import React from 'react';

import { Flex, Space } from 'antd';

import { AnchorLinkParams, AnchorLinks } from '../../../../AnchorLinks/AnchorLinks';
import { ReportDescriptionSection } from '../../../../renders/RenderDescriptionSection';
import { IdentifiedJobs } from '../../../../renders/RenderIdentifiedJobs';
import { JTBD } from 'src/@types/report';
import { Icon } from 'src/components/Common/Icon';
import styles from '../../../../renders/RenderDescriptionSection.module.scss';

interface ReportJTBDProps {
    jtbd: JTBD;
}

export const ReportJTBD: React.FC<ReportJTBDProps> = ({ jtbd }) => {
    const links = getAnchorLinks(jtbd);

    return (
        <Space direction="vertical" size={12} style={{ width: '100%' }}>
            <AnchorLinks links={links} />
            <Flex gap={12} vertical style={{ padding: '0', alignItems: 'center' }}>
                <div
                    className={styles.maxWidth}
                    style={{
                        height: 'calc(100vh - 210px)',
                        overflowY: 'auto',
                        padding: '20px',
                    }}
                >
                    <div
                        id="dentified-jobs"
                        style={{
                            borderRadius: '16px',
                            padding: '16px',
                            margin: '0',
                        }}
                    >
                        <Space direction="vertical" size={12} style={{ width: '100%' }}>
                            <h2
                                style={{
                                    transition: 'font-size 0.2s ease',
                                    fontSize: '18px',
                                    lineHeight: '130%',
                                    margin: 0,
                                    fontVariant: 'normal',
                                    fontWeight: 'bold',
                                }}
                            >
                                Identified Jobs
                            </h2>
                            <IdentifiedJobs jobs={jtbd.identified_jobs} />
                        </Space>
                    </div>
                    <ReportDescriptionSection
                        id="jtbd_solutions"
                        title="Solutions"
                        items={jtbd.solutions}
                        iconSymbol={
                            <Icon
                                name="handPoint"
                                width={20}
                                height={20}
                                fill="currentColor"
                                className={styles.anchorIcon}
                                style={{ transform: 'translateY(-1px)'}}
                            />
                        }
                    />
                    <ReportDescriptionSection
                        id="jtbd_outcomes"
                        title="Outcomes"
                        items={jtbd.outcomes}
                        iconSymbol={
                            <Icon
                                name="rosette"
                                width={17}
                                height={17}
                                fill="currentColor"
                                className={styles.anchorIcon}
                            />
                        }
                    />
                </div>
            </Flex>
        </Space>
    );
};

const getAnchorLinks = (jtbd: JTBD): AnchorLinkParams[] => {
    return [
        {
            href: '#identified-jobs',
            title: 'Identified Jobs',
            iconSymbol: (
                <Icon
                    name="hammerCircle"
                    width={15}
                    height={15}
                    fill="currentColor"
                    className={styles.anchorIcon}
                />
            ),
            count: jtbd.identified_jobs.length,
        },
        {
            href: '#jtbd_solutions',
            title: 'Solutions',
            iconSymbol: (
                <Icon
                    name="handPoint"
                    width={17}
                    height={17}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(1px)'}}
                />
            ),
            count: jtbd.solutions.length,
        },
        {
            href: '#jtbd_outcomes',
            title: 'Outcomes',
            iconSymbol: (
                <Icon
                    name="rosette"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className={styles.anchorIcon}
                />
            ),
            count: jtbd.outcomes.length,
        },
    ];
};
