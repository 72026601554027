import { Empty, Flex } from 'antd';

export const ErrorTabContent: React.FC = () => (
    <Flex
        justify="center"
        align="flex-start"
        style={{
            height: 'calc(100vh - 150px)',
            paddingTop: '30%',
        }}
    >
        <Empty description={`Something went wrong. We are working on resolving problem already and will contact you soon.`}></Empty>
    </Flex>
);
