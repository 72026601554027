import { BalancesResponse, ProfileResponse } from '../@types/profile';
import config from '../config';
import { concatUrl } from './helpers/url-helper';
import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';

const { PROFILE_API_URL } = config;

class ProfileNewService {
    prefix = '/api/v1/profile';

    async ensureProfile() {
        const url = new URL(concatUrl(PROFILE_API_URL, `${this.prefix}/ensure`)).toString();

        const response = await axios.post(url);

        if (response.status !== 204) {
            throw new Error('Failed to ensure profile');
        }
    }

    async getBalances(): Promise<BalancesResponse> {
        const url = new URL(concatUrl(PROFILE_API_URL, `${this.prefix}/balances`)).toString();

        const response = await axios.get(url);

        if (response.status === 200) {
            return camelcaseKeys(response.data, { deep: true });
        }

        throw new Error('Failed to fetch profile balances');
    }

    async claimTokens(): Promise<boolean> {
        const url = new URL(concatUrl(PROFILE_API_URL, `${this.prefix}/balances/claim`)).toString();

        try {
            const response = await axios.post(url);

            if (response.status === 204) {
                return true;
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 429) {
                    return false;
                }
            }
        }

        throw new Error('Failed to claim tokens');
    }
}

export const profileService = new ProfileNewService();
