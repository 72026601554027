import { Divider, Flex } from 'antd';

export const MenuProfileDivider: React.FC = () => (
    <Flex style={{ padding: '0 5px', width: '100%' }}>
        <Divider
            style={{
                height: '0.5px',
                margin: '4px 0',
                // backgroundColor: '#3E3E4266',
            }}
        />
    </Flex>
);
