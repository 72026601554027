import React, { useState } from 'react';
import { Segmented, Button } from 'antd';
import { Icon } from 'src/components/Common/Icon';

import { getAllKeys } from 'src/components/Report/helpers/JobKeys';

import { useIsSetupContextAvailable } from '../ReportSetupContext';
import { useReportTabsContext } from './ReportTabsContext';
import { ReportInsights, ReportJTBD, ReportProfile, ReportRecommendations, ReportSetup, ReportTranscript } from './Tabs';
import { ErrorTabContent } from './Tabs/ErrorTabContent';
import { LoadingContent } from './Tabs/LoadingContent';
import { ReportAssistant } from './Tabs/ReportAssistant/ReportAssistant';
import { ReportStatus } from 'src/@types/enums';
import { DetailedReportResponse } from 'src/@types/jtbd';

import styles from './ReportTabs.module.scss';
import { Color } from 'antd/es/color-picker';

interface ReportTabsProps {
    report: DetailedReportResponse;
    reportId?: string;
}

const ReportTabs: React.FC<ReportTabsProps> = ({ report, reportId }) => {
    const { expandedKeysState } = useReportTabsContext();
    const isSetupContextAvailable = useIsSetupContextAvailable();

    const [activeTab, setActiveTab] = useState<string>(getDefaultActiveTab(report));

    const [expandedKeys, setExpandedKeys] = expandedKeysState;

    const toggleExpandAll = () => {
        const allKeys = getAllKeys(report);
        setExpandedKeys(expandedKeys.length > 0 ? [] : allKeys);
    };

    const showExpandAllButton = !['setup', 'transcript', 'assistant'].includes(activeTab);
    const isSetupAvailable = reportId !== undefined && isSetupContextAvailable && report.setup;
    const isAssistantAvailable =
        reportId && report.transcripts && (report.profile || report.insights || report.jtbd || report.recommendations);

    const items = [
        isSetupAvailable && {
            key: 'setup', // 􀍟
            label: (
                <div className={styles.tabLabel}>
                    <Icon name="gear" width={17} height={17} fill="currentColor" className={styles.setupIcon} />
                    <span>Setup</span>
                </div>
            ),
            children: <ReportSetup />,
        },
        report.transcripts &&
            report.transcripts.length > 0 && {
                key: 'transcript', // 􀌮
                label: (
                    <div className={styles.tabLabel}>
                        <Icon name="quoteBubble" width={16} height={16} fill="currentColor" className={styles.setupIcon} />
                        <span>Transcript</span>
                    </div>
                ),
                children: <ReportTranscript transcripts={report.transcripts} />,
            },
        report.profile && {
            key: 'profile', // 􀉮
            label: (
                <div className={styles.tabLabel}>
                    <Icon name="personCircle" width={16} height={16} fill="currentColor" className={styles.setupIcon} />
                    <span>Profile</span>
                </div>
            ),
            children: <ReportProfile profile={report.profile} />,
        },
        report.insights && {
            key: 'insights', // 􀫸
            label: (
                <div className={styles.tabLabel}>
                    <Icon name="sparkle" width={15} height={15} fill="currentColor" className={styles.setupIcon} />
                    <span>Insights</span>
                </div>
            ),
            children: <ReportInsights insights={report.insights} />,
        },
        report.jtbd && {
            key: 'jtbd', // 􀵫
            label: (
                <div className={styles.tabLabel}>
                    <Icon name="textWord" width={16} height={16} fill="currentColor" className={styles.setupIcon} />
                    <span>JTBD</span>
                </div>
            ),
            children: <ReportJTBD jtbd={report.jtbd} />,
        },
        report.recommendations && {
            key: 'recommendations', // 􂨪
            label: (
                <div className={styles.tabLabel}>
                    <Icon name="chevronRightDotted" width={14} height={14} fill="currentColor" className={styles.setupIcon} />
                    <span>Actions</span>
                </div>
            ),
            children: <ReportRecommendations recommendations={report.recommendations} />,
        },
        isAssistantAvailable && {
            key: 'assistant', // 􀆿
            label: (
                <div className={styles.tabLabel}>
                    <Icon name="sparkles" width={20} height={20} fill="currentColor" className={styles.setupIcon} />
                    <span>AI Chat</span>
                </div>
            ),
            children: <ReportAssistant reportId={reportId} />,
        },
    ].filter(Boolean) as { key: string; label: React.ReactNode; children: React.ReactNode }[];

    const options = items.map(item => ({
        label: item.label,
        value: item.key,
    }));

    const activeItem = items.find(item => item.key === activeTab);

    if (report.status === ReportStatus.Error) {
        return <ErrorTabContent />;
    }

    if (report.status === ReportStatus.InQueue || report.status === ReportStatus.InProgress) {
        return <LoadingContent />;
    }

    return (
        <div>
            <div className={styles.tabControlContainer}>
                <div className={styles.segmentedContainer}>
                    <Segmented
                        className={styles.customOption}
                        options={options}
                        size="large"
                        value={activeTab}
                        onChange={(value) => setActiveTab(value as string)}
                    />
                </div>
                {showExpandAllButton && report.status === ReportStatus.Finished && (
                    <Button className={styles.actionButton} onClick={toggleExpandAll}>
                        {expandedKeys.length > 0 ? 'Collapse all' : 'Expand all'}
                    </Button>
                )}
            </div>
            <div>{activeItem && activeItem.children}</div>
        </div>
    );
};

export default ReportTabs;

const getDefaultActiveTab = (report: DetailedReportResponse): string => {
    if (report.profile) return 'profile';
    if (report.transcripts && report.transcripts.length > 0) return 'transcript';
    return 'setup';
};

