import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ArrowUpOutlined } from '@ant-design/icons';
import { Button, Dropdown, Flex, Space, Typography, message, theme } from 'antd';

import { ReactComponent as MenuIconFeedback } from 'src/assets/images/menu/menu-icon-feedback.svg';
import { ReactComponent as MenuIconLogout } from 'src/assets/images/menu/menu-icon-logout.svg';
import { ReactComponent as MenuIconTokens } from 'src/assets/images/menu/menu-icon-tokens.svg';

import config from '../../config';
import { profileService } from '../../services/profile.service';
import { AseedText } from '../AseedTypography/AseedText/AseedText';
import { TokenPurchaseModal } from '../TokenPurchaseModal/TokenPurchaseModal';
import { useBalance } from './BalanceContext';
import { MenuProfileDivider } from './renders/Dividers';
import { MenuButton } from './renders/MenuButton';
import { MenuCard } from './renders/MenuCard';
import defaultAvatar from 'src/assets/images/aseed_profile.png';
import authService from 'src/services/auth.service';

import styles from './Menu.module.scss';

declare global {
    interface Window {
        tiptop: any;
    }
}

const { Text } = Typography;

const AseedMenu: React.FC = () => {
    const navigate = useNavigate();
    const [isTokenModalOpen, setIsTokenModalOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { balances, loading, refreshBalance } = useBalance();

    const { TIPOPPAY_PUBLIC_ID } = config;

    const {
        token: { colorPrimary, colorText },
    } = theme.useToken();

    useEffect(() => {
        refreshBalance();
    }, [refreshBalance]);

    const handleSignOut = async () => {
        try {
            await authService.logout();
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    const handleClaimTokens = async () => {
        const result = await profileService.claimTokens();
        if (result) {
            message.success('Tokens claimed successfully');
        } else {
            message.warning('Tokens already claimed');
        }
    };

    const handleOpenTokenModal = () => {
        if (balances?.currencyRate) {
            setIsTokenModalOpen(true);
            setDropdownOpen(false);
        } else {
            message.error('Currency rate is not available');
        }
    };

    const handleBuyTokens = async (tokenCount: number, amount: number, currency: string) => {
        setIsTokenModalOpen(false);
        const currentUser = await authService.getCurrentUser();
        const invoiceId = crypto.randomUUID();
        console.log(`Amount is ${amount}, rounded is ${Math.round(amount)}`);
        const widget = new window.tiptop.Widget({ language: 'en-US' });
        widget.pay(
            'charge',
            {
                publicId: TIPOPPAY_PUBLIC_ID,
                description: `Tokens amount: ${tokenCount}`,
                amount: Math.round(amount),
                currency: currency,
                skin: 'mini',
                autoClose: 3,
                data: {
                    AuthId: currentUser!.uid,
                    Email: currentUser?.email ?? '',
                    TokenCount: tokenCount,
                },
                invoiceId: invoiceId,
            },
            {
                onSuccess: (options: any) => {
                    //message.success('Оплата прошла успешно:', options);
                    refreshBalance();
                    console.log('options:', options);
                },
                onFail: (reason: any, options: any) => {
                    //message.error('Ошибка при оплате', reason);
                    console.log('Reason:', reason);
                    console.log('options:', options);
                },
                onComplete: (paymentResult: any, options: any) => {
                    console.log('Платеж завершен:', paymentResult);
                    console.log('options:', options);
                },
            }
        );
    };

    const handleFeedbackClick = () => {
        window.open('https://aseed.canny.io/feedback', '_blank');
    };

    const availableTokens = balances?.availableTokens ?? 0;

    const subMenu = () => {
        return (
            <MenuCard style={{ padding: '8px 8px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)' }}>
                <Flex vertical={true} align="flex-start" gap={12}>
                    <Flex gap={12} justify="space-between" style={{ width: '100%' }}>
                        <Button
                            type="text"
                            onClick={handleOpenTokenModal}
                            style={{ fontWeight: 500, fontSize: 18, color: colorText, padding: 5 }}
                        >
                            <Flex align="center" gap={12}>
                                <MenuIconTokens fill={colorText} />
                                <AseedText>Buy Tokens</AseedText>
                            </Flex>
                        </Button>
                        <Text
                            type="secondary"
                            style={{
                                backgroundColor: `${colorPrimary}20`,
                                color: colorPrimary,
                                padding: '4px 12px',
                                borderRadius: '9px',
                                fontWeight: 500,
                            }}
                        >
                            {availableTokens} tokens
                        </Text>
                    </Flex>

                    {/*<MenuProfileDivider />*/}

                    <Flex justify="space-between" style={{ width: '100%' }}>
                        <MenuButton text="Feedback" icon={<MenuIconFeedback fill={colorText} />} onClick={handleFeedbackClick} />
                        <ArrowUpOutlined style={{ transform: 'rotate(45deg)' }} />
                    </Flex>
                    <MenuProfileDivider />
                    <MenuButton text="Sign Out" icon={<MenuIconLogout fill={colorText} />} onClick={handleSignOut} />
                </Flex>
            </MenuCard>
        );
    };

    return (
        <>
            <Dropdown trigger={['click']} dropdownRender={() => subMenu()} open={dropdownOpen} onOpenChange={setDropdownOpen}>
                <img className={userAvatar} src={defaultAvatar} alt="" />
            </Dropdown>
            {balances?.currencyRate && (
                <TokenPurchaseModal
                    isOpen={isTokenModalOpen}
                    onClose={() => setIsTokenModalOpen(false)}
                    onPurchase={handleBuyTokens}
                    currencyRate={balances.currencyRate}
                />
            )}
        </>
    );
};

export default AseedMenu;
const { userAvatar } = styles;
