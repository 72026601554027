import React, { Dispatch, SetStateAction } from 'react';

import { Button, Flex, Layout, theme } from 'antd';

import FooterBorder from 'src/assets/images/footer/footer-border.svg';
import FooterIconAudio from 'src/assets/images/footer/footer-icon-audio.svg';
import FooterIconLink from 'src/assets/images/footer/footer-icon-link.svg';
import FooterIconText from 'src/assets/images/footer/footer-icon-text.svg';
import FooterIconUpload from 'src/assets/images/footer/footer-icon-upload.svg';
import FooterIconVideo from 'src/assets/images/footer/footer-icon-video.svg';
import FooterIconZip from 'src/assets/images/footer/footer-icon-zip.svg';

const { Footer } = Layout;

interface StartFooterProps {
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const StartFooter: React.FC<StartFooterProps> = ({ setIsModalOpen }) => {
    const {
        token: {
            colorTextBase,
            size,
            screenXL,
            sizeLG,
            paddingXL,
            marginSM,
            marginLG,
            marginXS,
            fontWeightStrong,
            fontSizeXL,
            fontSizeLG,
            lineHeightHeading3,
        },
    } = theme.useToken();

    return (
        <Footer style={{ background: '#F4F5F8', border: '4px solid #9c99df', borderTop: 'none' }}>
            <Flex
                justify="center"
                vertical
                style={{
                    backgroundImage: `url(${FooterBorder})`,
                    maxWidth: screenXL - 100,
                    margin: 'auto',
                    padding: paddingXL,
                    borderRadius: sizeLG,
                }}
            >
                <div style={{ textAlign: 'center', margin: 'auto' }}>
                    <Flex justify="center" wrap>
                        <img src={FooterIconUpload} alt="" />
                        <div style={{ fontSize: fontSizeXL, marginLeft: marginSM, color: colorTextBase }}>
                            Get Insights From Your Own Interview
                        </div>
                    </Flex>
                    <div style={{ marginTop: marginSM, fontSize: fontSizeLG, lineHeight: lineHeightHeading3 }}>
                        <div>Simply upload interview files in audio, video, or text format.</div>
                        <div>We support various formats to streamline the process.</div>
                    </div>
                    <Flex justify="center" gap={marginSM} wrap style={{ marginTop: marginLG }}>
                        <Flex
                            justify="center"
                            align="center"
                            vertical
                            style={{
                                width: size * 5,
                                height: size * 5,
                                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                borderRadius: size,
                            }}
                        >
                            <img style={{ marginTop: marginXS, marginBottom: marginXS }} src={FooterIconAudio} alt="" />
                            <div style={{ fontWeight: fontWeightStrong }}>audio</div>
                        </Flex>
                        <Flex
                            justify="center"
                            align="center"
                            vertical
                            style={{
                                width: size * 5,
                                height: size * 5,
                                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                borderRadius: size,
                            }}
                        >
                            <img style={{ marginTop: marginXS, marginBottom: marginXS }} src={FooterIconVideo} alt="" />
                            <div style={{ fontWeight: fontWeightStrong }}>video</div>
                        </Flex>
                        <Flex
                            justify="center"
                            align="center"
                            vertical
                            style={{
                                width: size * 5,
                                height: size * 5,
                                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                borderRadius: size,
                            }}
                        >
                            <img style={{ marginTop: marginXS, marginBottom: marginXS }} src={FooterIconText} alt="" />
                            <div style={{ fontWeight: fontWeightStrong }}>text</div>
                        </Flex>
                        <Flex
                            justify="center"
                            align="center"
                            vertical
                            style={{
                                width: size * 5,
                                height: size * 5,
                                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                borderRadius: size,
                            }}
                        >
                            <img style={{ marginTop: marginXS, marginBottom: marginXS }} src={FooterIconZip} alt="" />
                            <div style={{ fontWeight: fontWeightStrong }}>zip</div>
                        </Flex>
                        <Flex
                            justify="center"
                            align="center"
                            vertical
                            style={{
                                width: size * 5,
                                height: size * 5,
                                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                borderRadius: size,
                            }}
                        >
                            <img style={{ marginTop: marginXS, marginBottom: marginXS }} src={FooterIconLink} alt="" />
                            <div style={{ fontWeight: fontWeightStrong }}>link</div>
                        </Flex>
                    </Flex>
                    <Button
                        size="large"
                        style={{ marginTop: marginSM, width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.05)' }}
                        onClick={() => setIsModalOpen(true)}
                    >
                        Upload file
                    </Button>
                </div>
            </Flex>
        </Footer>
    );
};
