import React, { useState } from 'react';

import { Flex, theme } from 'antd';

import { AseedText } from '../AseedTypography/AseedText/AseedText';

interface MessageBubbleProps {
    isRespondent: boolean;
    knownName?: string;
    text: string;
}

const MessageBubble: React.FC<MessageBubbleProps> = ({ isRespondent, knownName, text }) => {
    const [isHovered, setIsHovered] = useState(false);
    const {
        token: { colorPrimary },
    } = theme.useToken();

    // Вычисляем фон для обычного и hover состояний
    const normalBackgroundColor = isRespondent ? `${colorPrimary}15` : '#E5E5EA70';
    const hoverBackgroundColor = isRespondent ? `${colorPrimary}25` : '#E5E5EA90';

    const backgroundColor = isHovered ? hoverBackgroundColor : normalBackgroundColor;
    const borderColor = isRespondent ? colorPrimary : '#E5E5EA';

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: isRespondent ? 'flex-end' : 'flex-start',
                marginBottom: '14px',
            }}
        >
            <div
                style={{
                    maxWidth: '70%',
                    padding: '16px',
                    borderRadius: '16px',
                    borderBottomLeftRadius: isRespondent ? '16px' : '16px',
                    borderBottomRightRadius: isRespondent ? '16px' : '16px',
                    backgroundColor,
                    border: isHovered ? `1px solid ${colorPrimary}80` : '1px solid transparent',
                    boxShadow: isHovered ? '0 4px 12px rgba(35, 9, 49, 0.08)' : 'none',
                    transition: 'border 0.2s ease, box-shadow 0.2s ease',
                    cursor: 'pointer'
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <Flex vertical gap={6}>
                {knownName && <AseedText active styles={{ opacity: 0.4 }}>{knownName}</AseedText>}
                    <AseedText description styles={{ whiteSpace: 'pre-wrap' }}>
                        {text}
                    </AseedText>
                </Flex>
            </div>
        </div>
    );
};

export default MessageBubble;
