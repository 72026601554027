import React from 'react';

import { Flex, Modal, theme } from 'antd';

import { CheckEmail } from './CheckEmail';
import { ConfirmEmail } from './ConfirmEmail';
import { ForgotPassword } from './ForgotPassword';
import { NotInWhiteList } from './NotInWhiteList';
import { SignIn } from './SignIn';
import { SignUp } from './SignUp';

import './modal.css';

interface AuthModalProps {
    isModalOpen: boolean;
    onOk: () => void;
    onCancel: () => void;
    page?: ModalPages;
}

export enum ModalPages {
    SignIn = 'Sign In',
    SignUp = 'Sign Up',
    ConfirmEmail = 'Confirm Email',
    ForgotPassword = 'Forgot Password',
    CheckEmail = 'Check Email',
    NotInWhiteList = 'Not in WhiteList',
}

export const AuthModal: React.FC<AuthModalProps> = ({ isModalOpen, onOk, onCancel, page }) => {
    const {
        token: { colorPrimary, fontSizeHeading1, margin },
    } = theme.useToken();

    const [email, setEmail] = React.useState('');

    return (
        <Modal
            title={
                <Flex justify="center" style={{ color: colorPrimary, fontSize: fontSizeHeading1, marginTop: margin }}>
                    {page}
                </Flex>
            }
            open={isModalOpen}
            onOk={onOk}
            onCancel={onCancel}
            footer={null}
            closeIcon={null}
            style={{ maxWidth: 430 }}
            width="calc(100vw - 32px)"
        >
            {page === ModalPages.SignIn && <SignIn setEmail={setEmail} onCancel={onCancel} />}
            {page === ModalPages.SignUp && <SignUp setEmail={setEmail} onCancel={onCancel} />}
            {page === ModalPages.ConfirmEmail && <ConfirmEmail email={email} />}
            {page === ModalPages.NotInWhiteList && <NotInWhiteList email={email} />}
            {page === ModalPages.ForgotPassword && <ForgotPassword setEmail={setEmail} />}
            {page === ModalPages.CheckEmail && <CheckEmail email={email} />}
        </Modal>
    );
};
