import React from 'react';

import { Typography, theme } from 'antd';

const { Text } = Typography;
const { useToken } = theme;

interface AseedTextProps {
    children: React.ReactNode;
    description?: boolean;
    active?: boolean;
    styles?: object;
}

export const AseedText: React.FC<AseedTextProps> = ({ children, description = false, active = false, styles }) => {
    const { colorTextSecondary, colorText } = useToken().token;

    const baseFontWeight = description ? 350 : 400;
    const fontWeight = active ? baseFontWeight + 100 : baseFontWeight;

    return (
        <Text
            style={{
                transition: 'font-size 0.2s ease',
                margin: 0,
                fontVariant: 'normal',
                fontWeight: fontWeight,
                fontSize: '16px',
                lineHeight: '130%',
                letterSpacing: active ? '-0.185px' : '0px',
                color: description ? colorTextSecondary : colorText,
                ...styles,
            }}
        >
            {children}
        </Text>
    );
};
