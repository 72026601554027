import React from 'react';

import { Flex, Space } from 'antd';

import { AnchorLinkParams, AnchorLinks } from '../../../../AnchorLinks/AnchorLinks';
import { ReportDescriptionSection } from '../../../../renders/RenderDescriptionSection';
import { Profile } from 'src/@types/report';
import { Icon } from 'src/components/Common/Icon';
import styles from '../../../../renders/RenderDescriptionSection.module.scss';

interface ReportProfileProps {
    profile: Profile;
}

export const ReportProfile: React.FC<ReportProfileProps> = ({ profile }) => {
    const links = getAnchorLinks(profile);
    const sections = getSections(profile);

    return (
        <Space direction="vertical" size={12} style={{ width: '100%' }}>
            <AnchorLinks links={links} />
            <Flex gap={12} vertical style={{ padding: '0', alignItems: 'center' }}>
                <div
                    className={styles.maxWidth}
                    style={{
                        height: 'calc(100vh - 210px)',
                        overflowY: 'auto',
                        padding: '20px',
                    }}
                >
                    {sections.map(({ id, title, items, iconSymbol }) => (
                        <ReportDescriptionSection key={id} id={id} title={title} items={items} iconSymbol={iconSymbol} />
                    ))}
                </div>
            </Flex>
        </Space>
    );
};

const getAnchorLinks = (profile: Profile): AnchorLinkParams[] => {
    return [
        {
            href: '#general-info',
            iconSymbol: (
                <Icon
                    name="personFill"
                    width={17}
                    height={17}
                    fill="currentColor"
                    className={styles.anchorIcon}
                />
            ),
            title: 'General Info',
        },
        {
            href: '#goals',
            title: 'Goals',
            iconSymbol: (
                <Icon
                    name="mappinEllipse"
                    width={17}
                    height={17}
                    fill="currentColor"
                    className={styles.anchorIcon}
                />
            ),
            count: profile.goals.length,
        },
        {
            href: '#motivations',
            title: 'Motivations',
            iconSymbol: (
                <Icon
                    name="keyFill"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className={styles.anchorIcon}
                />
            ),
            count: profile.motivations.length,
        },
        {
            href: '#pain-points',
            title: 'Pain Points',
            iconSymbol: (
                <Icon
                    name="boltFill"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className={styles.anchorIcon}
                />
            ),
            count: profile.pain_points.length,
        },
        {
            href: '#emotions',
            title: 'Emotions',
            iconSymbol: (
                <Icon
                    name="heart"
                    width={15}
                    height={15}
                    fill="currentColor"
                    className={styles.anchorIcon}
                />
            ),
            count: profile.emotions.length,
        },
        {
            href: '#tools',
            title: 'Tools',
            iconSymbol: (
                <Icon
                    name="handPoint"
                    width={17}
                    height={17}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(1px)'}}
                />
            ),
            count: profile.tools.length,
        },
    ];
};

const getSections = (profile: Profile) => {
    return [
        {
            id: 'general-info',
            title: 'General Info',
            items: profile.general_info,
            iconSymbol: (
                <Icon
                    name="personFill"
                    width={17}
                    height={17}
                    fill="currentColor"
                    className={styles.anchorIcon}
                />
            ),
        },
        {
            id: 'goals',
            title: 'Goals',
            items: profile.goals,
            iconSymbol: (
                <Icon
                    name="mappinEllipse"
                    width={20}
                    height={20}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(-2px)' }}
                />
            ),
        },
        {
            id: 'motivations',
            title: 'Motivations',
            items: profile.motivations,
            iconSymbol: (
                <Icon
                    name="keyFill"
                    width={20}
                    height={20}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(-1px)'}}
                />
            ),
        },
        {
            id: 'pain-points',
            title: 'Pain Points',
            items: profile.pain_points,
            iconSymbol: (
                <Icon
                    name="boltFill"
                    width={18}
                    height={18}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(-2px)'}}
                />
            ),
        },
        {
            id: 'emotions',
            title: 'Emotions',
            items: profile.emotions,
            iconSymbol: (
                <Icon
                    name="heart"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(-1px)' }}
                />
            ),
        },
        {
            id: 'tools',
            title: 'Tools',
            items: profile.tools,
            iconSymbol: (
                <Icon
                    name="handPoint"
                    width={20}
                    height={20}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(-1px)' }}
                />
            ),
        },
    ];
};
