import React, { useEffect, useMemo, useState } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Button, Flex, Modal, Typography, theme } from 'antd';

import { CurrencyRate } from '../../@types/profile';

import styles from './TokenPurchaseModal.module.scss';

const { Text, Title } = Typography;

interface TokenPurchaseModalProps {
    isOpen: boolean;
    onClose: () => void;
    onPurchase: (tokenCount: number, amount: number, currency: string) => void;
    currencyRate: CurrencyRate;
}

const TOKEN_COUNTS = [500, 1000, 2000, 5000, 10000, 20000];

const formatNumber = (num: number): string => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const TokenPurchaseModal: React.FC<TokenPurchaseModalProps> = ({ isOpen, onClose, onPurchase, currencyRate }) => {
    const [selectedCount, setSelectedCount] = useState(1000);
    const [isKazakhstan, setIsKazakhstan] = useState(true);
    const { token } = theme.useToken();

    useEffect(() => {
        const detectCountry = async () => {
            try {
                const response = await fetch('https://ipapi.co/json/');
                const data = await response.json();
                //setIsKazakhstan(data.country_code === 'KZ');
                setIsKazakhstan(true);
            } catch (error) {
                console.error('Error detecting country:', error);
                //setIsKazakhstan(false);
                setIsKazakhstan(true);
            }
        };

        detectCountry();
    }, []);

    const calculatePrice = (tokens: number) => {
        const usdPrice = tokens / currencyRate.usdToToken;
        if (isKazakhstan) {
            return usdPrice / currencyRate.kztToUsd;
        }
        return usdPrice;
    };

    const getCurrencySymbol = () => (isKazakhstan ? ' ₸' : '$');

    const tokenPrice = isKazakhstan
        ? `${(1 / currencyRate.usdToToken / currencyRate.kztToUsd).toFixed(2)} ₸`
        : `$${(1 / currencyRate.usdToToken).toFixed(2)}`;

    const getCurrency = () => (isKazakhstan ? 'KZT' : 'USD');

    return (
        <Modal open={isOpen} onCancel={onClose} footer={null} width={400} title={null} closeIcon={null}>
            <div className={styles.modalContent}>
                <div className={styles.insetContainer}>
                    <Button type="text" className={styles.closeButton} icon={<CloseOutlined />} onClick={onClose} />
                    <Title level={4} className={styles.title}>
                        Buy More Tokens
                    </Title>
                    <Text className={styles.description}>
                        Choose the number of tokens you'd like to purchase. These tokens can be used anytime and do not expire.
                    </Text>
                    <div className={styles.divider} />
                    <div className={styles.tokenButtons}>
                        {TOKEN_COUNTS.map(amount => (
                            <Button
                                key={amount}
                                className={`${styles.tokenButton} ${selectedCount === amount ? styles.selected : ''}`}
                                onClick={() => setSelectedCount(amount)}
                            >
                                {formatNumber(amount)}
                            </Button>
                        ))}
                    </div>
                    <Text className={styles.tokenInfo}>1,000 tokens = ~10 reports</Text>
                </div>

                <div className={styles.paymentSection}>
                    <div className={styles.paymentRow}>
                        <div className={styles.tokenRate}>
                            <span className={styles.tokenRateText}>1 token = {tokenPrice}</span>
                            {/* <span className={styles.rateIcon}>􀅵</span> */}
                        </div>
                        <div className={styles.totalPayment}>
                            <span className={styles.totalText}>
                                {isKazakhstan
                                    ? `Total: ${formatNumber(parseFloat(calculatePrice(selectedCount).toFixed(2)))}${getCurrencySymbol()}`
                                    : `Total: ${getCurrencySymbol()}${formatNumber(parseFloat(calculatePrice(selectedCount).toFixed(2)))}`}
                            </span>
                            <Button
                                type="primary"
                                className={styles.submitButton}
                                onClick={() => onPurchase(selectedCount, calculatePrice(selectedCount), getCurrency())}
                            >
                                Proceed to Payment
                            </Button>
                        </div>
                    </div>
                    <div className={styles.infoMessage}>
                        Temporarily, only Kazakhstan bank cards are{'\u00A0'}accepted. Sorry for any inconvenience.
                    </div>
                </div>
            </div>
        </Modal>
    );
};
