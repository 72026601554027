import React from 'react';

import { Flex, Space } from 'antd';

import { AnchorLinkParams, AnchorLinks } from '../../../../AnchorLinks/AnchorLinks';
import { ReportDescriptionSection } from '../../../../renders/RenderDescriptionSection';
import { Recommendations } from 'src/@types/report';
import { Icon } from 'src/components/Common/Icon';
import styles from '../../../../renders/RenderDescriptionSection.module.scss';

interface ReportRecommendationsProps {
    recommendations: Recommendations;
}

export const ReportRecommendations: React.FC<ReportRecommendationsProps> = ({ recommendations }) => {
    const links = getAnchorLinks(recommendations);
    const sections = getSections(recommendations);

    return (
        <Space direction="vertical" size={12} style={{ width: '100%' }}>
            <AnchorLinks links={links} />
            <Flex gap={12} vertical style={{ padding: '0', alignItems: 'center' }}>
                <div
                    className={styles.maxWidth}
                    style={{ height: 'calc(100vh - 210px)', overflowY: 'auto', padding: '20px' }}
                >
                    {sections.map(({ id, title, items, iconSymbol }) => (
                        <ReportDescriptionSection key={id} id={id} title={title} items={items} iconSymbol={iconSymbol} />
                    ))}
                </div>
            </Flex>
        </Space>
    );
};

const getAnchorLinks = (recommendations: Recommendations): AnchorLinkParams[] => {
    return [
        {
            href: '#actionable-steps',
            title: 'Actionable Steps',
            iconSymbol: (
                <Icon
                    name="checklist"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(2px)'}}
                />
            ),
            count: recommendations.actionable_steps.length,
        },
        {
            href: '#product-improvements',
            title: 'Product Improvements',
            iconSymbol: (
                <Icon
                    name="checkmarkSeal"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(2px)'}}
                />
            ),
            count: recommendations.product_improvements.length,
        },
        {
            href: '#service-enhancements',
            title: 'Service Enhancements',
            iconSymbol: (
                <Icon
                    name="star"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    // style={{ transform: 'translateY(1px)'}}
                />
            ),
            count: recommendations.service_enhancements.length,
        },
    ];
};

const getSections = (recommendations: Recommendations) => {
    return [
        {
            id: 'actionable-steps',
            title: 'Actionable Steps',
            items: recommendations.actionable_steps,
            iconSymbol: (
                <Icon
                    name="checklist"
                    width={18}
                    height={18}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(1px)'}}
                />
            ),
        },
        {
            id: 'product-improvements',
            title: 'Product Improvements',
            items: recommendations.product_improvements,
            iconSymbol: (
                <Icon
                    name="checkmarkSeal"
                    width={17}
                    height={17}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(1px)'}}
                />
            ),
        },
        {
            id: 'service-enhancements',
            title: 'Service Enhancements',
            items: recommendations.service_enhancements,
            iconSymbol: (
                <Icon
                    name="star"
                    width={17}
                    height={17}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(-3px)'}}
                />
            ),
        },
    ];
};
