import { JTBD, Job, SolutionsDescription, TranscriptExample } from 'src/@types/report';

export class JTBDFormatter {
    private hierarchicalOutput: string[] = [];
    private descriptionsOutput: string[] = [];
    private output: string[] = [];
    private jtbd: JTBD | null = null;

    private formatExample(example: TranscriptExample): string {
        return `- ${example.id}: ${example.text}`;
    }

    private formatJobHierarchy(job: Job, prefix: string = ''): void {
        const jobInfo = `${prefix}. ${job.title}`;
        this.hierarchicalOutput.push(jobInfo);

        if (job.sub_jobs) {
            job.sub_jobs.forEach((subJob, index) => {
                const subPrefix = prefix ? `${prefix}.${index + 1}` : `${index + 1}`;
                this.formatJobHierarchy(subJob, subPrefix);
            });
        }
    }

    private formatJobDescription(job: Job, prefix: string = ''): void {
        this.descriptionsOutput.push(`### ${prefix}. ${job.title}\n`);

        // Sources
        this.descriptionsOutput.push('- `sources:`');
        if (job.examples && job.examples.length > 0) {
            job.examples.forEach(example => {
                this.descriptionsOutput.push(`  ${this.formatExample(example)}`);
            });
        } else {
            this.descriptionsOutput.push('  ...');
        }

        // Context / segment
        this.descriptionsOutput.push('\n`context / segment:`');
        this.descriptionsOutput.push(job.job_story_when || '...');

        // Goal / outcome
        this.descriptionsOutput.push('\n`goal / outcome:`');
        this.descriptionsOutput.push(job.job_story_want || '...');

        // Higher-level job
        this.descriptionsOutput.push('\n`higher-level job:`');
        this.descriptionsOutput.push(job.job_story_so_that || '...');

        // Dominant solution
        this.descriptionsOutput.push('\n`dominant solution:`');
        this.descriptionsOutput.push(job.dominant_solution || '...');

        // Problems with current solution
        this.descriptionsOutput.push('\n`problems with solution:`');
        this.descriptionsOutput.push(job.problems_with_current_solution || '...');

        // Emotions
        this.descriptionsOutput.push('\n`emotions:`');
        this.descriptionsOutput.push(job.emotions || '...');

        // Separator
        this.descriptionsOutput.push('\n---\n');

        // Recursively format sub-jobs
        if (job.sub_jobs) {
            job.sub_jobs.forEach((subJob, index) => {
                const subPrefix = prefix ? `${prefix}.${index + 1}` : `${index + 1}`;
                this.formatJobDescription(subJob, subPrefix);
            });
        }
    }

    private formatSolutions(solutions: SolutionsDescription[] | undefined): void {
        const solutionsDict: { [key: string]: string[] } = {};
        this.collectSolutionsFromJobs(this.jtbd!.identified_jobs, solutionsDict);

        if (Object.keys(solutionsDict).length > 0) {
            this.output.push('## Solutions');
            for (const [title, solutionList] of Object.entries(solutionsDict)) {
                this.output.push(`- ${title}`);
                solutionList.forEach(sol => {
                    this.output.push(`\t- ${sol}`);
                });
            }
            this.output.push('\n');
        }
    }

    private collectSolutionsFromJobs(jobs: Job[], solutionsDict: { [key: string]: string[] }): void {
        jobs.forEach(job => {
            if (job.dominant_solution) {
                if (!solutionsDict[job.title]) {
                    solutionsDict[job.title] = [];
                }
                solutionsDict[job.title].push(job.dominant_solution);
            }
            if (job.sub_jobs) {
                this.collectSolutionsFromJobs(job.sub_jobs, solutionsDict);
            }
        });
    }

    private formatOutcomes(outcomes: SolutionsDescription[] | undefined): void {
        const outcomesDict: { [key: string]: string[] } = {};
        this.collectOutcomesFromJobs(this.jtbd!.identified_jobs, outcomesDict);

        if (Object.keys(outcomesDict).length > 0) {
            this.output.push('## Outcomes');
            for (const [title, outcomeList] of Object.entries(outcomesDict)) {
                this.output.push(`- ${title}`);
                outcomeList.forEach(out => {
                    this.output.push(`\t- ${out}`);
                });
            }
            this.output.push('\n');
        }
    }

    private collectOutcomesFromJobs(jobs: Job[], outcomesDict: { [key: string]: string[] }): void {
        jobs.forEach(job => {
            if (job.job_story_want) {
                if (!outcomesDict[job.title]) {
                    outcomesDict[job.title] = [];
                }
                outcomesDict[job.title].push(job.job_story_want);
            }
            if (job.sub_jobs) {
                this.collectOutcomesFromJobs(job.sub_jobs, outcomesDict);
            }
        });
    }

    public formatJTBD(jtbd: JTBD): string {
        this.jtbd = jtbd;
        this.hierarchicalOutput = [];
        this.descriptionsOutput = [];
        this.output = [];

        // Format hierarchical list
        this.hierarchicalOutput.push('## Hierarchical list of jobs:\n');
        jtbd.identified_jobs.forEach((job, index) => {
            const prefix = (index + 1).toString();
            this.formatJobHierarchy(job, prefix);
        });

        // Format job descriptions
        this.descriptionsOutput.push('\n## Job Descriptions\n');
        jtbd.identified_jobs.forEach((job, index) => {
            const prefix = (index + 1).toString();
            this.formatJobDescription(job, prefix);
        });

        // Combine outputs
        this.output = [...this.hierarchicalOutput, ...this.descriptionsOutput];

        // Format solutions and outcomes
        this.formatSolutions(jtbd.solutions);
        this.formatOutcomes(jtbd.outcomes);

        return this.output.join('\n');
    }
}
