import React, { Dispatch, SetStateAction, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { GoogleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Flex, Form, FormProps, Input, theme } from 'antd';

import { useBalance } from 'src/components/Menu/BalanceContext';

import { PATH } from '../../constants/paths';
import authService from '../../services/auth.service';
import { firebaseAuth } from '../../services/firebase/AseedFirebase';
import { profileService } from '../../services/profile.service';
import { ModalContext } from '../Header/Header';
import { ModalPages } from './index';

type FieldType = {
    fullname: string;
    email: string;
    password: string;
    agree: boolean;
};

interface ConfirmEmailProps {
    setEmail: Dispatch<SetStateAction<string>>;
    onCancel: Dispatch<SetStateAction<void>>;
}

const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = errorInfo => {
    console.log('Failed:', errorInfo);
};

export const SignUp: React.FC<ConfirmEmailProps> = ({ setEmail, onCancel }) => {
    const navigate = useNavigate();
    const {
        token: {
            colorPrimary,
            margin,
            marginMD,
            marginXL,
            marginXXL,
            marginXS,
            sizeXXL,
            paddingLG,
            fontSizeLG,
            controlHeightSM,
            colorWhite,
            colorError,
        },
    } = theme.useToken();

    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const { setModalPage } = useContext(ModalContext);
    const { refreshBalance } = useBalance();

    const onFinish: FormProps<FieldType>['onFinish'] = async ({ email, password, fullname }) => {
        setLoading(true);
        try {
            await authService.signUp(email, password, fullname);
            await refreshBalance();
            setModalPage(ModalPages.ConfirmEmail);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleGoogleSignIn = async () => {
        setLoading(true);
        try {
            await authService.signInWithGoogle();
            console.log(firebaseAuth.currentUser);
            await profileService.ensureProfile();
            await refreshBalance();
            onCancel();
            navigate(PATH.WORKSPACE);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Flex justify="center" wrap>
            <Form
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="on"
                style={{ marginTop: marginXL, width: 330 }}
            >
                <Form.Item<FieldType>
                    name="fullname"
                    rules={[{ required: true, message: 'Please input your full name' }]}
                    style={{ marginBottom: marginMD }}
                >
                    <Input
                        size="large"
                        placeholder="Your full name"
                        style={{
                            height: sizeXXL,
                            borderRadius: sizeXXL,
                            paddingLeft: paddingLG,
                            paddingRight: paddingLG,
                            fontSize: fontSizeLG + 2,
                            color: '#6A6A70',
                            backgroundColor: 'rgba(255, 255, 255, 0.4)',
                        }}
                    />
                </Form.Item>

                <Form.Item<FieldType>
                    name="email"
                    rules={[{ required: true, message: 'Please input your email' }]}
                    style={{ marginBottom: marginMD }}
                >
                    <Input
                        size="large"
                        placeholder="Your email"
                        style={{
                            height: sizeXXL,
                            borderRadius: sizeXXL,
                            paddingLeft: paddingLG,
                            paddingRight: paddingLG,
                            fontSize: fontSizeLG + 2,
                            color: '#6A6A70',
                            backgroundColor: 'rgba(255, 255, 255, 0.4)',
                        }}
                        onChange={e => setEmail(e.target.value)}
                    />
                </Form.Item>

                <Form.Item<FieldType>
                    name="password"
                    rules={[{ required: true, message: 'Please input your password' }]}
                    style={{ marginBottom: marginXL }}
                >
                    <Input.Password
                        size="large"
                        placeholder="Your password"
                        style={{
                            height: sizeXXL,
                            borderRadius: sizeXXL,
                            paddingLeft: paddingLG,
                            paddingRight: paddingLG,
                            fontSize: fontSizeLG + 2,
                            color: '#6A6A70',
                            backgroundColor: 'rgba(255, 255, 255, 0.4)',
                        }}
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        disabled={loading}
                        loading={loading}
                        style={{ borderRadius: sizeXXL, height: sizeXXL, width: '100%' }}
                    >
                        Sign Up
                    </Button>
                </Form.Item>

                <Divider>or</Divider>

                <Button
                    icon={<GoogleOutlined />}
                    size="large"
                    onClick={handleGoogleSignIn}
                    disabled={loading}
                    loading={loading}
                    style={{ borderRadius: sizeXXL, height: sizeXXL, width: '100%' }}
                >
                    Sign Up with Google
                </Button>

                <Divider style={{ borderColor: colorWhite, marginTop: marginXXL, color: colorError }}>{error}</Divider>

                <Flex justify="center" style={{ fontSize: fontSizeLG + 2, marginBottom: margin }}>
                    <div style={{ color: '#6A6A70', marginRight: marginXS }}>Do you have an Account?</div>
                    <div style={{ color: colorPrimary, cursor: 'pointer' }} onClick={() => setModalPage(ModalPages.SignIn)}>
                        Sign In
                    </div>
                </Flex>
            </Form>
        </Flex>
    );
};
