import React from 'react';

import { BalanceProvider } from './components/Menu/BalanceContext';

import App from './App/App';
import { initSentry } from './services/sentry';
import ReactDOM from 'react-dom/client';

initSentry();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    // <React.StrictMode>
    <BalanceProvider>
        <App />
    </BalanceProvider>
    // </React.StrictMode>
);
