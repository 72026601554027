import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Typography, theme } from 'antd';

import { AseedTitle } from 'src/components/AseedTypography/AseedTitle/AseedTitle';
import AseedMenu from 'src/components/Menu/Menu';

import { DetailedReportResponse } from '../../../@types/jtbd';
import { EditableTitle } from '../../Common/EditableTitle';
import ReportExport from './ReportExport';
import { useIsSetupContextAvailable } from './ReportSetupContext';
import { useReportWidgetContext } from './ReportWidgetContext';
import { PATH } from 'src/constants/paths';

import styles from './ReportHeader.module.scss';

const { Title } = Typography;
const { contentLayout, link, titleDemo, projectTitle, exportBtn, combinedContainer } = styles;

const ReportHeader: React.FC<{ report: DetailedReportResponse }> = ({ report }) => {
    const {
        token: { colorTextSecondary },
    } = theme.useToken();

    const { updateName } = useReportWidgetContext();

    const [name, setName] = useState<string>(report.name);

    const onChangeName = (newName: string) => {
        if (!newName) return;
        setName(newName);
        updateName(newName);
    };

    const isNotDemo = useIsSetupContextAvailable();

    if (!isNotDemo) {
        return (
            <div className={contentLayout}>
                <div
                    style={{
                        padding: '0 30px',
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        gap: '8px',
                    }}
                >
                    <Title className={titleDemo} level={3}>
                        {name}
                    </Title>
                    <AseedTitle>•</AseedTitle>
                    <AseedTitle level={2} fontWeight={400}>
                        {report.setup?.respondent_known_names[0]}
                    </AseedTitle>
                    <Typography style={{ verticalAlign: 'bottom', color: colorTextSecondary, position: 'relative', top: 3 }}>
                        (20 Jul 2024)
                    </Typography>
                    <div style={{ marginLeft: 'auto' }}>
                        <ReportExport className={exportBtn} report={report} />
                    </div>
                </div>

                {/*<div style={{ marginLeft: 'auto' }}>*/}
                {/*    <AseedMenu />*/}
                {/*</div>*/}
            </div>
        );
    }

    return (
        <div className={contentLayout}>
            <div className={combinedContainer}>
                <Link className={link} to={PATH.WORKSPACE}>
                    􀆉
                </Link>
                <EditableTitle value={name} onChange={onChangeName} isEditable={isNotDemo} className={projectTitle} />
                <ReportExport className={exportBtn} report={report} />
            </div>
            <div style={{ marginLeft: 'auto' }}>
                <AseedMenu />
            </div>
        </div>
    );
};

export default ReportHeader;
