import React, { useState } from 'react';

import { Anchor, Typography } from 'antd';

import styles from './AnchorLinks.module.scss';

const { Text } = Typography;

export interface AnchorLinkParams {
    href: string;
    iconSymbol: React.ReactNode;
    title: string;
    count?: number;
}

export const AnchorLinks: React.FC<{
    links: AnchorLinkParams[];
}> = ({ links }) => {
    const [activeLink, setActiveLink] = useState<string>('');

    const linkItems = links.map(({ href, title, iconSymbol, count }) => {
        const linkHref = `#${href.slice(1)}`;
        return {
            key: href,
            href: linkHref,
            title: (
                <Text className={activeLink === linkHref ? styles.linkTitleActive : styles.linkTitle}>
                    {iconSymbol} {title}
                    {count !== undefined && <span className={styles.linkTitleCounter}>{count}</span>}
                </Text>
            ),
        };
    });

    return <Anchor replace onChange={setActiveLink} direction="horizontal" items={linkItems} />;
};
