import { Switch } from 'antd';

import { ReactComponent as MenuIconLock } from 'src/assets/images/menu/menu-icon-lock.svg';

interface SetupCheckBoxProps {
    isLocked?: boolean;
    checked?: boolean;
    onChange?: (checked: boolean) => void;
}

export const SetupCheckBox: React.FC<SetupCheckBoxProps> = ({ onChange, checked, isLocked }) => {
    const getCheckedChildren = () => {
        if (isLocked) {
            return <MenuIconLock style={{ width: 12, height: 10 }} />;
        }
        return null;
    };

    return (
        <Switch
            checked={checked}
            style={{ transform: 'scale(1.2)' }}
            onChange={onChange}
            disabled={isLocked}
            checkedChildren={getCheckedChildren()}
        />
    );
};
