import { firebaseAuth, githubProvider, googleProvider } from './firebase/AseedFirebase';
import axios from 'axios';
import { User, UserCredential, onAuthStateChanged, updateProfile } from 'firebase/auth';
import {
    createUserWithEmailAndPassword,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
} from 'firebase/auth';

class AuthService {
    private currentUser: User | null = null;

    constructor() {
        // Слушаем изменения состояния аутентификации
        onAuthStateChanged(firebaseAuth, user => {
            this.currentUser = user;
            if (user) {
                this.setAuthHeader(user);
            } else {
                this.clearTokens();
            }
        });
    }

    // Методы аутентификации
    async signUp(email: string, password: string, fullName: string): Promise<UserCredential> {
        try {
            const userCredential = await createUserWithEmailAndPassword(firebaseAuth, email, password);
            await updateProfile(userCredential.user, {
                displayName: fullName,
            });
            await sendEmailVerification(userCredential.user);
            return userCredential;
        } catch (error: any) {
            throw this.handleError(error);
        }
    }

    async signIn(email: string, password: string): Promise<UserCredential> {
        try {
            return await signInWithEmailAndPassword(firebaseAuth, email, password);
        } catch (error: any) {
            throw this.handleError(error);
        }
    }

    async signInWithGoogle(): Promise<UserCredential> {
        try {
            return await signInWithPopup(firebaseAuth, googleProvider);
        } catch (error: any) {
            throw this.handleError(error);
        }
    }

    async signInWithGithub(): Promise<UserCredential> {
        try {
            return await signInWithPopup(firebaseAuth, githubProvider);
        } catch (error: any) {
            throw this.handleError(error);
        }
    }

    async resetPassword(email: string): Promise<void> {
        try {
            await sendPasswordResetEmail(firebaseAuth, email);
        } catch (error: any) {
            throw this.handleError(error);
        }
    }

    async logout(): Promise<void> {
        try {
            await signOut(firebaseAuth);
            this.clearTokens();
            this.currentUser = null;
            window.location.href = '/';
        } catch (error: any) {
            console.error('Logout error:', error);
            this.clearTokens();
            this.currentUser = null;
            window.location.href = '/';
        }
    }

    // Вспомогательные методы
    isAuthenticated(): boolean {
        return !!this.currentUser;
    }

    async getCurrentUser(): Promise<User | null> {
        if (!this.currentUser) {
            return new Promise(resolve => {
                const unsubscribe = onAuthStateChanged(firebaseAuth, user => {
                    unsubscribe();
                    this.currentUser = user;
                    resolve(user);
                });
            });
        }
        return this.currentUser;
    }

    private async setAuthHeader(user: User) {
        const token = await user.getIdToken();
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    private clearTokens() {
        delete axios.defaults.headers.common['Authorization'];
    }

    private handleError(error: any): Error {
        let message = 'An error occurred';

        switch (error.code) {
            case 'auth/email-already-in-use':
                message = 'Email already exists';
                break;
            case 'auth/invalid-email':
                message = 'Invalid email';
                break;
            case 'auth/operation-not-allowed':
                message = 'Operation not allowed';
                break;
            case 'auth/weak-password':
                message = 'Weak password';
                break;
            case 'auth/user-disabled':
                message = 'User disabled';
                break;
            case 'auth/user-not-found':
                message = 'User not found';
                break;
            case 'auth/wrong-password':
                message = 'Wrong password';
                break;
            case 'auth/invalid-credential':
                message = 'Wrong email or password';
                break;
            case 'auth/popup-closed-by-user':
                message = 'Sign in popup was closed';
                break;
            case 'auth/account-exists-with-different-credential':
                message = 'An account already exists with the same email address but different sign-in credentials';
                break;
            case 'auth/popup-blocked':
                message = 'Sign in popup was blocked by the browser';
                break;
            case 'auth/cancelled-popup-request':
                message = 'Sign in was cancelled';
                break;
        }

        return new Error(message);
    }
}

// Инициализация сервиса
const authService = new AuthService();

// Настройка интерцепторов axios
axios.interceptors.request.use(
    async config => {
        const user = await authService.getCurrentUser();
        if (user) {
            const token = await user.getIdToken(true);
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

axios.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;
        if (error?.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const user = await authService.getCurrentUser();
            if (user) {
                try {
                    const newToken = await user.getIdToken(true);
                    originalRequest.headers.Authorization = `Bearer ${newToken}`;
                    return axios(originalRequest);
                } catch (refreshError) {
                    await authService.logout();
                    return Promise.reject(refreshError);
                }
            }
        }
        return Promise.reject(error);
    }
);

export default authService;
