import React from 'react';

import { Flex, Space } from 'antd';

import { Icon } from 'src/components/Common/Icon';
import { AnchorLinkParams, AnchorLinks } from '../../../../AnchorLinks/AnchorLinks';
import { ReportDescriptionSection } from '../../../../renders/RenderDescriptionSection';
import { Insights } from 'src/@types/report';
import styles from '../../../../renders/RenderDescriptionSection.module.scss';

interface ReportInsightsProps {
    insights: Insights;
}

export const ReportInsights: React.FC<ReportInsightsProps> = ({ insights }) => {
    const links = getAnchorLinks(insights);
    const sections = getSections(insights);

    return (
        <Space direction="vertical" size={12} style={{ width: '100%' }}>
            <AnchorLinks links={links} />
            <Flex gap={12} vertical style={{ padding: '0', alignItems: 'center' }}>
                <div
                    className={styles.maxWidth}
                    style={{
                        height: 'calc(100vh - 210px)',
                        overflowY: 'auto',
                        padding: '20px',
                    }}
                >
                    {sections.map(({ id, title, items, iconSymbol }) => (
                        <ReportDescriptionSection key={id} id={id} title={title} items={items} iconSymbol={iconSymbol} />
                    ))}
                </div>
            </Flex>
        </Space>
    );
};

const getAnchorLinks = (insights: Insights): AnchorLinkParams[] => {
    return [
        {
            href: '#key-insights',
            title: 'Key Insights',
            iconSymbol: (
                <Icon
                    name="handThumbsup"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className={styles.anchorIcon}
                />
            ),
            count: insights.key_insights.length,
        },
        {
            href: '#barriers',
            title: 'Barriers',
            iconSymbol: (
                <Icon
                    name="handRaised"
                    width={17}
                    height={17}
                    fill="currentColor"
                    className={styles.anchorIcon}
                />
            ),
            count: insights.barriers.length,
        },
        {
            href: '#engagements',
            title: 'Engagements',
            iconSymbol: (
                <Icon
                    name="handsClap"
                    width={18}
                    height={18}
                    fill="currentColor"
                    className={styles.anchorIcon}
                />
            ),
            count: insights.engagements.length,
        },
        {
            href: '#expectations',
            title: 'Expectations',
            iconSymbol: (
                <Icon
                    name="handPointRight"
                    width={17}
                    height={17}
                    fill="currentColor"
                    className={styles.anchorIcon}
                />
            ),
            count: insights.expectations.length,
        },
    ];
};

const getSections = (insights: Insights) => {
    return [
        {
            id: 'key-insights',
            title: 'Key Insights',
            items: insights.key_insights,
            iconSymbol: (
                <Icon
                    name="handThumbsup"
                    width={18}
                    height={18}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(-2px)'}}
                />
            ),
        },
        {
            id: 'barriers',
            title: 'Barriers',
            items: insights.barriers,
            iconSymbol: (
                <Icon
                    name="handRaised"
                    width={18}
                    height={18}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(-2px)'}}
                />
            ),
        },
        {
            id: 'engagements',
            title: 'Engagements',
            items: insights.engagements,
            iconSymbol: (
                <Icon
                    name="handsClap"
                    width={20}
                    height={20}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(-1px)'}}
                />
            ),
        },
        {
            id: 'expectations',
            title: 'Expectations',
            items: insights.expectations,
            iconSymbol: (
                <Icon
                    name="handPointRight"
                    width={19}
                    height={19}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(-1px)' }}
                />
            ),
        },
    ];
};
